<template>
<div class="tabs">
  <el-tabs v-model="tabIndex" type="card" @tab-remove="removeTabEvent" @tab-click="changeEvent">
    <el-tab-pane :key="item.code" v-for="item in tabs" :label="item.name" :name="item.index" :closable="closableEvent(item.code)"></el-tab-pane>
  </el-tabs>
  <el-dropdown class="closeAll" @command="commandEvent">
    <el-button icon="el-icon-arrow-down"></el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="closeAll">关闭全部</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["desktop", "tabs"]),
    tabIndex: {
      get() {
        return this.$store.state.tabIndex;
      },
      set(newValue) {
        this.$store.state.tabIndex = newValue;
      }
    },
  },
  methods: {
    ...mapMutations(["goToPage", "removeTabEvent", "removeAllTab"]),
    closableEvent(code) {
      let closables = ["WellCome", "Workbench", 'Desktop']
      return closables.indexOf(code) > -1 ? false : true
    },
    changeEvent(tab) {
      this.tabs.forEach(item => {
        if (item.index == tab.name) {
          this.goToPage(item);
        }
      });
    },
    commandEvent(command) {
      if (command == "closeAll") {
        this.removeAllTab();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;

  .closeAll {
    position: absolute;
    top: 3px;
    right: 10px;

    .el-button {
      width: 32px;
      height: 32px;
      padding: 6px 0px;
    }
  }
}
</style>
