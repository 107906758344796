<template>
<div class="logo">
  <span v-if="isCollapse"><img :src="logo.smallLogo" /></span>
  <span v-else><img :src="logo.bigLogo" /></span>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["isCollapse", "logo"]),
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.logo {
  height: 55px;
  line-height: 55px;
  background: #fff;
  text-align: center;
  overflow: hidden;
  text-align: center;

  span {
    display: block;
    height: 55px;
    padding: 5px;
    box-sizing: border-box;
  }

  img {
    height: 100%;
  }
}
</style>
