<template>
<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item v-for="menu in activeMenu.fullName.split(',')" :key="menu.index">{{menu}}</el-breadcrumb-item>
  <!-- <el-breadcrumb-item v-if="currTreeNode">{{currTreeNode.label}}</el-breadcrumb-item>
  <el-breadcrumb-item v-else>全部</el-breadcrumb-item> -->
</el-breadcrumb>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState([
      "currTreeNode",
      "activeMenu"
    ]),
  }
}
</script>

<style>

</style>
