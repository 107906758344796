<template>
<div>
  <el-badge :value="unreadMessageCount" :max="99" :hidden="unreadMessageCount <= 0" @click.native="openDrawerEvent">
    <i class="el-icon-message-solid"></i>
  </el-badge>
  <el-drawer title="全部通知" :append-to-body="false" :visible.sync="drawer">
    <div class="notice" v-loading="loading">
      <div class="readAll">
        <el-button type="text" @click="setupAllRead">全部标记为已读</el-button>
      </div>
      <div class="noticeLists" v-if="tableData.length">
        <dl v-for="(item,index) in tableData" :key="index" :class="{'read': item.status == 1}">
          <dt>{{item.createTime}}</dt>
          <dd>
            <p>{{item.title}}</p>
            <p>
              <span :title="item.content">{{item.content}}</span>
              <el-button type="text" @click="viewEvent(item)">查看详情</el-button>
            </p>
          </dd>
        </dl>
      </div>
      <div class="noData" v-else>
        暂无数据
      </div>
      <div class="pager" v-if="tableData.length">
        <vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize" :total="tablePage.total" :layouts="layouts" @page-change="pageChange"></vxe-pager>
      </div>
    </div>
  </el-drawer>
</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      tableData: [],
      layouts: [
        "PrevJump",
        "PrevPage",
        "Number",
        "NextPage",
        "NextJump",
        // "Sizes",
        "Total",
      ],
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      unreadMessageCount: 0
    };
  },

  computed: {
    ...mapGetters(['socketMsgs']),
  },

  watch: {
    //处理接收到的消息
    socketMsgs(val) {
      if (val != "连接成功") {
        this.getUnreadMessageCount();
      }
    },
  },

  created() {
    this.getUnreadMessageCount();
  },

  methods: {
    ...mapMutations(["goToPage"]),
    ...mapActions(["getListService", "queryEventService", ]),
    openDrawerEvent() {
      this.drawer = true;
      this.getLists();
    },

    // 获取通知消息的未读数量
    getUnreadMessageCount() {
      this.$Axios.get(`/sys/sysMessagePush/unreadMessageCount`).then(({ code, data }) => {
        this.unreadMessageCount = data || 0;
        this.drawer = false;
      });
    },

    setupAllRead() {
      this.$confirm("是否将消息全部设置为已读?", this.$t("confirmTitle"), {
        confirmButtonText: '  确定',
        cancelButtonText: '取消',
      }).then((type) => {
        if (type == "confirm") {
          this.$Axios.post(`/sys/sysMessagePush/readMessage`).then(({ code, data }) => {
            this.getUnreadMessageCount();
          });
        }
      }).catch(() => {

      });
    },

    // 获取数据
    getLists() {
      this.loading = true;
      this.getListService({
          service: "/sys/sysMessagePush",
          tablePage: this.tablePage,
          params: {},
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.tableData = data && data.records || [];
            this.tablePage.total = data && data.total || 0;
          }
          this.resetEvent();
        }).catch(err => {
          this.resetEvent();
        });
    },

    // 分页功能
    pageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.getLists();
    },

    viewEvent(row) {
      this.$Axios.post(`/sys/sysMessagePush/readMessage/${row.id}`).then(({ code, data }) => {
        if (code == 200) {
          this.getUnreadMessageCount();
          this.$confirm(row.content, row.title, {
            confirmButtonText: '查看详情',
            cancelButtonText: '取消',
          }).then((type) => {
            if (type == "confirm") {
              const Resources = JSON.parse(localStorage.resources);
              let item = this.$XEUtils.filterTree(Resources, item => item.code === row.path)[0];
              this.drawer = false;
              this.goToPage(item);
            }
          }).catch(() => {

          });
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-badge {
  cursor: pointer;

  i {
    color: #409EFF;
    font-size: 20px;
  }
}

.notice {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .readAll {
    height: 40px;
    padding: 0 20px;
    text-align: right;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
  }

  .noticeLists {
    flex: 1;
    overflow: auto;

    dl {
      padding-bottom: 20px;
      border-bottom: 1px dashed #ccc;
      overflow: hidden;

      dt {
        height: 50px;
        line-height: 50px;
      }

      dd {
        padding-left: 60px;
        background: url("../../assets/images/notice.png") no-repeat left center;
        background-size: 50px auto;

        p {
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #141933;
          font-weight: 700;

          span {
            width: 400px;
            line-height: 40px;
            white-space: nowrap;
            text-overflow: ellipsis;
            float: left;
            overflow: hidden;
          }

          .el-button {
            float: right;
          }
        }
      }

      &.read {
        opacity: 0.6;

        p {
          color: #666 !important;
        }
      }
    }
  }

  .noData {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

}
</style>
