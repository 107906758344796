<template>
  <div>
    <!-- <el-button plain @click="sendEvent">
    发送
  </el-button> -->
    <!-- 消息提醒 -->
    <vxe-modal ref="xModal" title="消息提醒" width="600" height="500" show-zoom>
      <template v-slot>
        <div v-html="notice.content" class=""></div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      notice: null,
    }
  },
  created() {
    this.initWebSocket();
  },

  computed: {
    ...mapGetters(['socketMsgs']),
  },

  watch: {
    //处理接收到的消息
    socketMsgs(val) {
      if (val != "连接成功") {
        this.notifyEvent(val);
      }
    },
  },

  methods: {
    ...mapMutations(["goToPage"]),
    ...mapActions(["webSocketInit"]),
    initWebSocket() {
      this.webSocketInit(); //初始化ws
    },

    sendEvent() {
      let msg = { "businessId": 1536533887671439362, "communityId": 1529703862507900930, "content": "【1-1-0801】房间于【2022-06-14 10:20:01】提交了投诉建议，请及时处理，联系人【岳柏利】，联系电话【18991337067】", "createTime": 1655173201963, "creatorId": 1529699690022641665, "id": 1536533892125802498, "orderColumns": {}, "path": "Complaints", "pushType": "1", "queryWrapper": { "customSqlSegment": "", "emptyOfEntity": true, "emptyOfNormal": true, "emptyOfWhere": true, "expression": { "groupBy": [], "having": [], "normal": [], "orderBy": [], "sqlSegment": "" }, "paramNameValuePairs": {}, "sqlSegment": "", "targetSql": "" }, "title": "投诉建议-推送模板", "updateTime": 1655173201963, "updaterId": 1529699690022641665 };
      let webSocketUrl = `/sys/openApi/sendMsg`;
      this.$Axios.post(webSocketUrl, {
        orgId: "1529696087060979713",
        message: JSON.stringify(msg),
      });
    },

    notifyEvent(val) {
      let _this = this;
      this.notice = JSON.parse(val);
      this.$notify({
        title: this.notice.title,
        type: "warning",
        message: this.notice.content,
        dangerouslyUseHTMLString: true,
        customClass: "notify",
        position: 'bottom-right',
        duration: 1000 * 60 * 1,
        onClick: () => {
          if (_this.notice.path) {
            const Resources = JSON.parse(localStorage.resources);
            let item = this.$XEUtils.filterTree(Resources, item => item.code === _this.notice.path)[0];
            _this.goToPage(item);
          } else {
            _this.$refs.xModal.open();
          }
        }
      });
    },
  }
}
</script>

<style lang="scss">
.notify {
  width: 400px;

  .el-notification__content {
    padding: 10px 0;
  }
}
</style>
