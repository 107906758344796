<template>
<div class="header">
  <div class="header_left">
    <el-button type="text" icon="fa fa-bars" @click="showMenu"></el-button>
    <Breadcrumb />
  </div>
  <div class="header_right">
    <Notice />
    <UserInfo />
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from "./Breadcrumb";
import UserInfo from "@/components/Base/userInfo.vue";
import Notice from "@/components/Base/Notice.vue"
export default {
  components: {
    Breadcrumb,
    Notice,
    UserInfo
  },
  data() {
    return {
      value: 1,
      userInfo: null,
    }
  },
  computed: {
    ...mapState([
      "isCollapse",
    ]),
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
  },
  methods: {
    ...mapActions(['logOutServe']),

    showMenu() {
      this.$store.state.isCollapse = !this.$store.state.isCollapse;
    },

    commandEvent(command) {
      if (command == "logout") {
        this.logOut();
      }
    },
    // 退出登录
    logOut() {
      let token = localStorage.token;
      if (!token) {
        localStorage.clear();
        this.$router.replace({ name: "Login" });
      }
      this.logOutServe()
        .then(({ code, data, message }) => {
          if (code == 200) {
            this.$message.success("退出系统");
            localStorage.clear();
            this.$router.replace({ name: "Login" });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 55px;
  display: flex;
  justify-content: space-between;

  .header_left {
    display: flex;
    align-items: center;
  }

  .header_right {

    display: flex;
    flex-direction: row;
    align-items: center;

    .el-badge {
      margin: 0 20px;
      cursor: pointer;
    }

    .user {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
</style>
