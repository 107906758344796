<template>
<el-container>
  <el-aside width="auto">
    <logo />
    <Menu />
  </el-aside>
  <el-container>
    <el-header height="55px">
      <Header />
    </el-header>
    <el-main>
      <Tabs />
      <div class="router">
        <Main />
      </div>
    </el-main>
  </el-container>
  <PushMsg/>
</el-container>
</template>

<script>
import { mapState } from "vuex";
import Logo from "./Logo";
import Menu from "./Menu";
import Header from "./Header";
import Main from "./Main";
import Tabs from "./Tabs";
import PushMsg from "./PushMsg";
export default {
  components: {
    Logo,
    Menu,
    Tabs,
    Header,
    Main,
    PushMsg
  },
  data() {
    return {
      fade: ""
    }
  },
  computed: {
    ...mapState([
      "isCollapse",
    ]),
  },

}
</script>

<style lang="scss" scoped>
.el-container {
  height: 100%;
  overflow: hidden;

  .el-header {
    padding: 0 20px;
    background: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
  }

  .el-aside {
    box-sizing: border-box;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .router {
    min-width: 1200px;
    height: 100%;
    overflow: hidden;
  }

  .el-main {
    padding: 0;
    height: 100%;
    background: #fff;
    border-left: 1px solid #eee;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

}
</style>
