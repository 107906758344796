// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/notice.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-badge[data-v-5621493c]{cursor:pointer}.el-badge i[data-v-5621493c]{color:#409eff;font-size:20px}.notice[data-v-5621493c]{height:100%;display:flex;flex-direction:column;padding:0 20px}.notice .readAll[data-v-5621493c]{height:40px;padding:0 20px;text-align:right;border-bottom:1px solid #ccc;overflow:hidden}.notice .noticeLists[data-v-5621493c]{flex:1;overflow:auto}.notice .noticeLists dl[data-v-5621493c]{padding-bottom:20px;border-bottom:1px dashed #ccc;overflow:hidden}.notice .noticeLists dl dt[data-v-5621493c]{height:50px;line-height:50px}.notice .noticeLists dl dd[data-v-5621493c]{padding-left:60px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0;background-size:50px auto}.notice .noticeLists dl dd p[data-v-5621493c]{height:30px;line-height:30px;font-size:14px;color:#141933;font-weight:700}.notice .noticeLists dl dd p span[data-v-5621493c]{width:400px;line-height:40px;white-space:nowrap;text-overflow:ellipsis;float:left;overflow:hidden}.notice .noticeLists dl dd p .el-button[data-v-5621493c]{float:right}.notice .noticeLists dl.read[data-v-5621493c]{opacity:.6}.notice .noticeLists dl.read p[data-v-5621493c]{color:#666!important}.notice .noData[data-v-5621493c]{flex:1;display:flex;align-items:center;justify-content:center;text-align:center}", ""]);
// Exports
module.exports = exports;
