<template>
<div>
  <el-dropdown @command="commandEvent" v-if="userInfo">
    <div class="userInfo">
      <el-avatar fit="fit" :size="32">
        <i class="el-icon-user-solid"></i>
      </el-avatar>
      <el-button type="text">{{ userInfo.username}}<i class="el-icon-arrow-down el-icon--right"></i></el-button>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(item, index) in dropdownlists" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  <el-dialog title="修改密码" :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="dialogVisible" width="668px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="原始密码：" prop="oldPassword">
        <el-input type="password" v-model="ruleForm.oldPassword" placeholder="请输入原始密码"></el-input>
      </el-form-item>
      <el-form-item label="设置密码：" prop="password">
        <el-input type="password" v-model="ruleForm.password" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="repeatPassword">
        <el-input type="password" v-model="ruleForm.repeatPassword" placeholder="请再次确认新密码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <el-dialog title="温馨提示" :visible.sync="prompt" :close-on-click-modal="false" width="30%">
    <span>密码修改成功！5S后跳转至登录页面，现在<el-button type="text" @click="logout">重新登录</el-button></span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="logout">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
export default {
  data() {
    const reg = /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,18}/
    var validatePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!reg.test(value)) {
        callback(new Error('必须包含字母（不区分大小写）、数字、特殊字符，至少6位，最多18位!'));
      } else {
        callback();
      }
    };

    var repeatPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (!reg.test(value)) {
        callback(new Error('必须包含字母（不区分大小写）、数字、特殊字符，至少6位，最多18位!'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      userInfo: null,
      dropdownlists: [{
          label: "修改密码",
          value: "changePwd",
        },
        {
          label: "安全退出",
          value: "logout",
        },
      ],
      dialogVisible: false,
      ruleForm: {
        oldPassword: "",
        password: "",
        repeatPassword: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入原始密码', trigger: 'blur' },
          // { validator: validatePwd, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePwd, trigger: 'blur' }
        ],
        repeatPassword: [
          { required: true, message: '请再次确认新密码', trigger: 'blur' },
          { validator: repeatPassword, trigger: 'blur' }
        ],
      },
      // !QAZ2wsx3edc
      prompt: false
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    ...mapMutations(["webSocketClose"]),
    ...mapActions(["logOutServe"]),
    commandEvent(command) {
      if (command == "changePwd") {
        this.dialogVisible = true;
      } else if (command == "logout") {
        this.logout();
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$Axios.post('sys/admin/updateSelfPassword', this.ruleForm).then(({ code, data, success, message, timestamp, }) => {
            if (code === 200 && success) {
              this.dialogVisible = false;
              this.prompt = true;
              this.$message.success("密码修改成功！");
            } else {
              this.$message.error(message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },

    // 退出登录
    logout() {
      this.webSocketClose();
      let token = localStorage.token;
      if (!token) {
        this.$router.push({ name: "Login" });
      } else {
        this.logOutServe({})
          .then(({ code, message }) => {
            if (code === 200) {
              this.$message.success("退出登录成功");
              setTimeout(() => {
                localStorage.clear();
                this.$router.push({ name: "Login" });
                location.reload();
              }, 500);
            } else {
              this.$message.error(message);
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;

  .el-avatar {
    margin-right: 10px;
  }
}
</style>
