<template>
<el-menu :default-active="tabIndex" :collapse="isCollapse" background-color="#2a3542" text-color="#fff" active-text-color="#409EFF" unique-opened>
  <el-submenu :index="menu.index" :key="menu.index" v-for="menu in menuLists">
    <template slot="title">
      <i class="iconfont" :class="menu.icon"></i>
      <span slot="title">{{menu.name}}</span>
    </template>
    <el-menu-item-group v-for="item in menu.children" :key="item.index">
      <el-menu-item :index="item.index" @click="goToPage(item)" v-if="item.type == '2'">
        <i class="iconfont" :class="item.icon" v-if="item.icon"></i>
        <span slot="title">{{item.name}}</span>
      </el-menu-item>
      <el-submenu :index="item.index" v-else>
        <template slot="title">
          <i class="iconfont" :class="item.icon" v-if="item.icon"></i>
          <span slot="title">{{item.name}}</span>
        </template>
        <el-menu-item :index="subMenu.index" v-for="subMenu in item.children" :key="subMenu.index" @click="goToPage(subMenu)">
          <i class="iconfont" :class="subMenu.icon" v-if="subMenu.icon"></i>
          <span slot="title">{{subMenu.name}}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu-item-group>
  </el-submenu>
</el-menu>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    ...mapState([
      "isCollapse",
      "menuLists",
      "desktop"
    ]),
    tabIndex: {
      get() {
        return this.$store.state.tabIndex;
      },
      set(newValue) {
        this.$store.state.tabIndex = newValue;
      }
    }
  },
  created() {
    // 初始化菜单效果
    if (localStorage.resources) {
      this.$store.state.menuLists = JSON.parse(localStorage.resources);
    } else {
      this.goToPage(this.desktop)
    }
  },

  methods: {
    ...mapMutations(["goToPage"]),
  }
};
</script>

<style lang="scss">
.el-menu {
  flex: 1;
  border: none;
  overflow: hidden auto;

  &:not(.el-menu--collapse) {
    width: 180px;
    box-sizing: border-box;
  }

  .el-menu--inline {
    overflow: hidden;
  }

  .el-menu-item-group__title {
    padding: 0;
  }

  i {
    color: #fff;
    margin-right: 10px;
  }

  i.iconfont {
    font-size: 18px;
  }
}
</style>
